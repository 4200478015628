import {Routes} from "@angular/router";

export const routes: Routes = [
    {
        path: "login",
        loadComponent: () => import("./index").then((m) => m.LoginPage),
    },
    {
        path: "logout",
        loadComponent: () => import("./logout/logout.page").then((m) => m.LogoutPage),
    },
    {path: "", redirectTo: "login", pathMatch: "full"},
];


