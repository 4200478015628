export const environment = {
  baseUrl: "https://toover-dev.azurewebsites.net/",
  env: "DEV",
  firebase: {
    projectId: "toover-dev",
    appId: "1:670060124002:web:880dda92175c350264be3b",
    storageBucket: "toover-dev.appspot.com",
    apiKey: "AIzaSyCLvuRlkW59_2IKRlZV82uTRTkEJVNxy2o",
    authDomain: "toover-dev.firebaseapp.com",
    messagingSenderId: "670060124002",
  },
  production: false,
  useEmulators: false,
};
